<script setup>
import { ref } from 'vue'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import route from 'ziggy-js'

const categories = ref([
  {
    lable: 'Nạp thẻ',
    url: route('recharge'),
  },
  {
    lable: 'Thanh toán momo',
    url: route('recharge.momo'),
  },
  {
    lable: 'Thanh toán Ngân hàng',
    url: route('recharge.bank'),
  },
])

const props = defineProps(['title_page', 'notes'])

const isActive = (url) => {
  const currentPath = window.location.pathname
  const normalizedCurrentPath = currentPath.endsWith('/')
    ? currentPath.slice(0, -1)
    : currentPath
  const normalizedUrlPath = new URL(url).pathname.endsWith('/')
    ? new URL(url).pathname.slice(0, -1)
    : new URL(url).pathname

  return normalizedCurrentPath === normalizedUrlPath
}
</script>
<template>
  <section class="">
    <div class="mx-auto max-w-7xl gap-10 mt-12 min-h-[400px]">
      <div class="border border-black p-5 mb-20 bg-white">
        <h2
          class="mb-5 font-['Inter'] text-3xl text-center text-black font-semibold"
        >
          {{ title_page }}
        </h2>
        <div class="flex flex-wrap sm:flex-nowrap px-5 py-6 sm:py-10 gap-4">
          <div
            class="flex flex-col border-black p-2 sm:px-8 py-6 gap-2 me-0 sm:me-3 rounded-lg w-full sm:w-[60%] bg-[#F2CEDF]"
          >
            <div class="flex flex-col gap-3">
              <h2 class="text-lg sm:text-2xl font-semibold font-['Inter'] mb-8">
                Chọn phương thức nạp thẻ
              </h2>

              <Disclosure
                v-for="(category, index) in categories"
                :key="index"
                as="div"
                :defaultOpen="isActive(category.url) ? true : false"
              >
                <DisclosureButton
                  :as="title_page.trim() == category.lable ? 'buttom' : 'a'"
                  :href="
                    title_page.trim() == category.lable ? '' : category.url
                  "
                >
                  <div
                    :class="[
                      'focus:outline-none w-full focus:ring-2 text-black p-4 bg-white border-b-2 border-gray-300',
                      isActive(category.url) ? '' : 'text-black',
                    ]"
                    :style="
                      isActive(category.url)
                        ? 'background:linear-gradient(90.00deg, rgb(189, 235, 235),rgb(234, 184, 242) 100%);'
                        : ''
                    "
                  >
                    <span
                      class="text-left text-base font-normal tracking-[-1%]"
                    >
                      {{ category.lable }}
                    </span>
                    <ChevronRightIcon
                      class="ui-open:rotate-90 ui-open:transform"
                    />
                  </div>
                </DisclosureButton>
                <DisclosurePanel>
                  <div class="bg-white">
                    <slot></slot>
                  </div>
                </DisclosurePanel>
              </Disclosure>
            </div>
          </div>

          <div class="w-full sm:w-[40%] bg-[#F2CEDF] rounded-lg">
            <div
              :class="[
                ' my-20 p-5',
                'ring-white/60  focus:outline-none focus:ring-2',
              ]"
            >
              <div v-html="notes"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
