<script setup>
import route from 'ziggy-js'
import { ListBulletIcon, ShoppingCartIcon } from '@heroicons/vue/24/solid'
import { ref } from 'vue'
import Drawer from './Drawer.vue'
import Logo from '../../images/logo.gif'

const props = defineProps(['user'])

const menu = [
  {
    url: route('home'),
    page: 'Trang chủ',
    istaget: false,
  },
  {
    url: route('recharge'),
    page: 'Nạp tiền',
    istaget: false,
  },
  {
    url: 'https://www.facebook.com/lukaku.romelu.587606',
    page: 'Liên hệ',
    istaget: true,
  },
  {
    url: 'https://www.facebook.com/profile.php?id=100083102053812',
    page: 'FanPage',
    istaget: true,
  },
  {
    url: 'https://www.facebook.com/lukaku.romelu.587606/posts/pfbid02s7LiW9k1PfyhV5nU7gBJwPd566VogpoFURAqWpWFxnqVD7ySVUYktHZH9ncP44zCl?rdid=tZEIWEGTzpemtIhx',
    page: 'Check Ut',
    istaget: true,
  },
  {
    url: route('policy'),
    page: 'Chính sách bảo hành',
    istaget: false,
  },
]

const isActive = (url) => {
  const currentPath = window.location.pathname
  const normalizedCurrentPath = currentPath.endsWith('/')
    ? currentPath.slice(0, -1)
    : currentPath
  const normalizedUrlPath = new URL(url).pathname.endsWith('/')
    ? new URL(url).pathname.slice(0, -1)
    : new URL(url).pathname

  return normalizedCurrentPath === normalizedUrlPath
}

const links = [
  { href: route('profile'), label: 'Thông tin người dùng' },
  { href: route('profile.historybill'), label: 'Lịch sử đơn hàng' },
  { href: route('profile.ChangePass'), label: 'Đổi mật khẩu' },
  { href: route('logout_client'), label: 'Đăng xuất' },
]

const formatCurrency = (value) => {
  return new Intl.NumberFormat('vi-VN', {
    style: 'currency',
    currency: 'VND',
  }).format(value)
}

const isShowModal = ref(false)

const handleOpen = () => {
  isShowModal.value = true
}

const onClose = () => {
  isShowModal.value = false
}
</script>

<template>
  <header
    class="bg-black sticky top-0 flex items-center justify-between gap-4 border-b border-gray-700 px-2 py-4 sm:px-6 md:px-8 lg:px-20 z-[99999]"
  >
    <button
      class="block md:hidden px-2 py-1 text-white"
      type="button"
      @click="handleOpen"
      aria-label="List Bullet Icon"
    >
      <ListBulletIcon class="h-5 w-5" />
    </button>
    <Drawer :isShowModal="isShowModal" :onClose="onClose">
      <div class="flex flex-col gap-2">
        <MenuItem
          :class="{
            'text-lg px-4': true,
            'text-white': !isActive(list.url),
            'text-blue-300': isActive(list.url),
          }"
          v-for="list in menu"
          :key="list.url"
        >
          <a :href="list.url" class="whitespace-nowrap text-xl font-bold">{{
            list.page
          }}</a>
        </MenuItem>
        <MenuItem>
          <form :action="route('category.search')" method="post">
            <slot></slot>
            <input-search
              :name="'category_name'"
              type="submit"
              class=""
            ></input-search>
          </form>
        </MenuItem>
        <MenuItem>
          <a
            :href="route('cart')"
            class="flex self-center justify-center border-2 border-gray-200 px-3 py-2 rounded-full"
            v-if="props.user"
          >
            <ShoppingCartIcon class="h-5 w-5 text-white" />
          </a>
        </MenuItem>
      </div>
    </Drawer>

    <div>
      <a href="/">
        <img
          alt="NGHIENGACHA"
          :src="Logo"
          class="ms-0 md:ms-5 h-auto lg:h-24 w-auto aspect-video"
        />
      </a>
    </div>

    <div class="flex items-center justify-center gap-5">
      <ul class="hidden gap-2 md:flex">
        <li
          :class="{
            'text-lg px-4': true,
            'text-white': !isActive(list.url),
            'text-blue-300': isActive(list.url),
          }"
          v-for="list in menu"
          :key="list.url"
        >
          <a
            :target="list.istaget ? 'backlink' : ''"
            :href="list.url"
            class="whitespace-nowrap text-xl font-normal font-sans"
            >{{ list.page }}</a
          >
        </li>
      </ul>
    </div>

    <div class="flex gap-3">
      <div class="ms-auto hidden sm:block">
        <form :action="route('category.search')" method="post">
          <slot></slot>
          <input-search
            :name="'category_name'"
            type="submit"
            class=""
          ></input-search>
        </form>
      </div>
    </div>
    <div class="flex gap-3" v-if="props.user">
      <div class="hidden md:flex gap-3 sm:w-auto ms-auto">
        <a
          :href="route('cart')"
          class="flex self-center justify-center px-3 py-2 rounded-full"
        >
          <ShoppingCartIcon class="h-8 w-8 text-white" />
        </a>
      </div>
      <div>
        <drop-down :user="props.user">
          <MenuItem
            as="template"
            class="block px-4 py-2 text-lg text-gray-700 font-bold max-w-[200px] overflow-hidden truncate"
          >
            Xin chào: {{ props.user.ten_hien_thi }}
          </MenuItem>

          <MenuItem as="template" class="block px-4 py-2 text-lg text-gray-700">
            Số dư:
            <span class="font-bold"
              >{{ formatCurrency(props.user.tien) }}
            </span>
          </MenuItem>
          <MenuItem v-for="link in links" :key="link.href" as="template">
            <a
              :href="link.href"
              :class="[
                isActive(link.href)
                  ? 'bg-gray-100 text-gray-900'
                  : 'text-gray-700',
                'block px-4 py-2 text-lg',
              ]"
            >
              {{ link.label }}
            </a>
          </MenuItem>
        </drop-down>
      </div>
    </div>
    <div class="flex gap-3" v-if="!props.user">
      <button class="border p-2 rounded bg-[#F2CEDF] hover:opacity-85">
        <a
          :href="route('login')"
          class="text-black whitespace-nowrap text-lg font-semibold font-['Inter']"
        >
          Đăng nhập</a
        >
      </button>
      <button class="bg-[#F2CEDF] p-2 rounded hover:opacity-85">
        <a
          :href="route('sigin_client')"
          class="text-black whitespace-nowrap text-lg font-semibold font-['Inter']"
          >Đăng ký</a
        >
      </button>
    </div>
  </header>
</template>
