<script setup>
import route from 'ziggy-js'
import Money from '../../components/icon/Money.vue'
</script>
<template>
  <h3 class="text-black text-lg sm:text-2xl font-bold font-['Inter']">
    TỔNG TIỀN
  </h3>
  <div class="flex items-center flex-wrap pb-2 border-b mt-3">
    <div class="md:w-1/2">
      <label
        for="name"
        class="text-black text-lg sm:text-xl font-semibold font-['Inter']"
        >TỔNG TIỀN:</label
      >
    </div>
    <div class="md:w-1/2 h16-bo-26 flex justify-end ms-auto">
      <label
        for="email"
        class="flex gap-1 text-black text-lg sm:text-xl font-semibold font-['Inter']"
      >
        {{ DisCount(calculateTotalcart(cart), discount) }}
      </label>
    </div>
  </div>
  <div class="flex py-2 flex-wrap border-b">
    <div class="md:w-1/2">
      <label
        for="message"
        class="text-black text-lg sm:text-xl font-semibold font-['Inter']"
        >Số dư hiện tại:</label
      >
    </div>
    <div class="md:w-1/2 flex justify-end ms-auto">
      <label
        for="message"
        class="flex gap-1 text-black text-lg sm:text-xl font-semibold font-['Inter']"
      >
        {{ formatCurrency(user.tien) }}
      </label>
    </div>
  </div>

  <div class="flex justify-between flex-wrap border-b py-2">
    <div class="md:w-1/2">
      <label
        for="message"
        class="text-black text-lg sm:text-xl font-semibold font-['Inter'] whitespace-nowrap"
        >Số tiền cần nạp thêm:</label
      >
    </div>
    <div class="md:w-1/2 h16-bo-26 flex justify-end ms-auto">
      <label
        for="message"
        class="flex gap-1 text-black text-lg sm:text-xl font-semibold font-['Inter']"
      >
        {{
          calculateTotal(cart, user.tien, discount) <= 0
            ? 0
            : formatCurrency(calculateTotal(cart, user.tien, discount))
        }}</label
      >
    </div>
  </div>

  <div
    class="col-md-12 col-sm-12 col-xs-12 padd-0 margin-border-dash border-bottom-dash"
  ></div>

  <div
    v-if="calculateTotal(cart, user.tien, discount) > 0"
    class="flex flex-col"
  >
    <div class="col-12 text-lg font-semibold font-['Inter'] text-black my-5">
      BẠN KHÔNG ĐỦ SỐ DƯ ĐỂ THANH TOÁN
    </div>
    <div class="col-12 mt-3">
      <a
        :href="route('recharge')"
        class="btn bg-red-600 text-white p-2 rounded"
        style="color: #fff"
      >
        <strong class="text-white text-lg font-semibold font-['Inter']"
          >Nạp Thêm Tiền</strong
        >
      </a>
    </div>
  </div>
  <div v-if="calculateTotal(cart, user.tien, discount) <= 0" class="mt-2">
    <div class="col-12">
      <form
        :action="
          $group == 'service'
            ? route('checkout', { product_type: 'service' })
            : route('checkout', { product_type: 'product' })
        "
        method="POST"
        enctype="multipart/form-data"
      >
        <slot></slot>
        <button
          class="btn bg-[#508C46] w-full sm:w-1/2 text-white p-2 rounded text-lg font-semibold font-['Inter']"
          type="submit"
        >
          Thanh toán
        </button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    user: {},
    cart: {},
    group: {},
    discount: {
      type: Array,
      default: 0,
    },
  },
  methods: {
    calculateTotal(cart, moneyUser, discount) {
      const carts = Object.values(cart)
      let total = 0
      carts.forEach((item) => {
        total += item.price * item.quantity
      })

      const finalTotal = total - moneyUser - discount
      if (finalTotal >= 0) {
        return finalTotal
      }
      return 0
    },

    calculateTotalcart(cart) {
      let carts = Object.values(cart)
      let total = 0
      carts.forEach((item) => {
        total += item.price * item.quantity
      })

      return total
    },
    formatCurrency(value) {
      return new Intl.NumberFormat('vi-VN', {
        style: 'currency',
        currency: 'VND',
      }).format(value)
    },

    DisCount(price, discount) {
      const pricenew = price - discount
      if (pricenew >= 0) {
        return new Intl.NumberFormat('vi-VN', {
          style: 'currency',
          currency: 'VND',
        }).format(pricenew)
      }
      return new Intl.NumberFormat('vi-VN', {
        style: 'currency',
        currency: 'VND',
      }).format(0)
    },
  },
}
</script>
