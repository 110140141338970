<template>
  <div class="mx-6 bg-[#F2CEDF]">
    <div class="grid grid-cols-1 sm:grid-cols-3 border border-[#A7A7A7]">
      <div
        class="flex-col items-center p-5 border-b sm:border-e border-[#A7A7A7]"
      >
        <img
          class="w-full aspect-video h-full border border-gray-400 p-1"
          :src="'/' + isImagePath(detail.hinh)"
          alt="Product Image"
        />
      </div>
      <div class="col-span-2">
        <p
          class="text-black text-lg font-medium capitalize font-['Donegal One'] py-2 px-3"
        >
          {{ detail.ten_game }}
        </p>
        <div class="px-3" v-html="detail.mota"></div>
        <div class="p-3 border-y border-[#A7A7A7] mt-2">
          <h6 class="text-lg font-normal text-black">Thông tin tài khoản</h6>
          <p>
            <b class="text-black font-extrabold">Tài khoản : </b>
            <span class="text-brown text-black text-lg font-normal">{{
              detail.tai_khoan_game
            }}</span>
          </p>
          <p class="py-2">
            <b class="text-black font-extrabold">Mật khẩu : </b>
            <span class="text-brown text-black text-lg font-normal">{{
              detail.password_game
            }}</span>
          </p>
        </div>
        <p class="text-red-500 p-3 text-lg font-normal">
          <b>Lưu ý: </b>{{ detail.notes }}
        </p>
      </div>
    </div>
  </div>

  <div class="px-6 grid grid-cols-1 md:grid-cols-2 gap-4 mt-3 mb-7 md:mt-5">
    <div class="p-0">
      <div class="container-thanhtoan frame_layout2 border border-gray-400">
        <div class="p-5 bg-[#F2CEDF]">
          <div class="mb-4">
            <b class="text-black text-lg sm:text-xl font-medium"
              >THÔNG TIN ĐƠN HÀNG</b
            >
          </div>
          <div class="card-body">
            <div class="flex">
              <div class="w-1/2">
                <div class="form-group">
                  <label class="text-black text-lg font-normal"
                    >Mã đơn hàng:</label
                  >
                </div>
              </div>
              <div class="w-1/2">
                <div class="form-group">
                  <label class="text-black"
                    ><b>#{{ detail.id }}</b></label
                  >
                </div>
              </div>
            </div>

            <div class="flex">
              <div class="w-1/2">
                <div class="form-group">
                  <label class="text-black text-lg font-normal"
                    >Thành tiền sản phẩm:</label
                  >
                </div>
              </div>
              <div class="w-1/2">
                <div class="form-group">
                  <label class="text-black font-normal text-lg">{{
                    formatCurrency(detail.gia_dh)
                  }}</label>
                </div>
              </div>
            </div>

            <div class="flex">
              <div class="w-1/2">
                <div class="form-group">
                  <label class="text-black text-lg font-normal"
                    >Ngày tạo:</label
                  >
                </div>
              </div>
              <div class="w-1/2">
                <div class="form-group">
                  <label class="text-black">{{
                    formatDate(detail.created_at)
                  }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from 'date-fns'
export default {
  props: ['detail'],
  methods: {
    formatDate(timestamp) {
      return format(new Date(timestamp), 'yyyy-MM-dd HH:mm:ss')
    },
    isImagePath(path) {
      const imagePathPrefix = 'assets/images'
      const check = path.includes(imagePathPrefix)
      if (check) {
        return path
      }
      return 'storage/' + path
    },
    formatCurrency(value) {
      return new Intl.NumberFormat('vi-VN', {
        style: 'currency',
        currency: 'VND',
      }).format(value)
    },
  },
}
</script>
