<script setup>
import { ref } from 'vue'

import route from 'ziggy-js'

const categories = ref([
  {
    lable: 'Lịch sử đơn hàng',
    url: route('profile.historybill'),
  },
  {
    lable: 'Lịch sử nạp',
    url: route('profile.historyPayment'),
  },
])

const profileInfo = ref([
  {
    lable: 'Thông tin chung',
    url: route('profile'),
  },
  {
    lable: 'Đổi mật khẩu',
    url: route('accoutnChange'),
  },
])

const profileTransaction = ref([
  {
    lable: 'Nạp tiền',
    url: route('recharge'),
  },
])

const props = defineProps(['title_page'])

const isActive = (url) => {
  const currentPath = window.location.pathname
  const normalizedCurrentPath = currentPath.endsWith('/')
    ? currentPath.slice(0, -1)
    : currentPath
  const normalizedUrlPath = new URL(url).pathname.endsWith('/')
    ? new URL(url).pathname.slice(0, -1)
    : new URL(url).pathname

  return normalizedCurrentPath === normalizedUrlPath
}
</script>
<template>
  <section class="">
    <div class="mx-auto max-w-7xl gap-10 mt-12 min-h-[400px] bg-white">
      <div class="border border-black p-5 mb-20">
        <h2
          class="mb-5 font-['Inter'] text-3xl text-center text-black font-semibold"
        >
          {{ title_page }}
        </h2>
        <div class="flex flex-wrap sm:flex-nowrap px-5 sm:px-20 py-6 sm:py-10">
          <div
            class="flex flex-col border-black px-8 py-6 gap-2 me-3 w-full rounded-lg sm:w-[40%] bg-[#F2CEDF]"
          >
            <div class="flex flex-col gap-3">
              <h2 class="text-lg sm:text-xl font-semibold font-['Inter']">
                Thông tin
              </h2>
              <a
                v-for="(profileInfo, index) in profileInfo"
                :key="index"
                :href="profileInfo.url"
                :class="[
                  '  focus:outline-none focus:ring-2 pb-2 border-b-2 border-gray-300',
                  isActive(profileInfo.url)
                    ? 'text-[#5259F0]'
                    : 'text-black  hover:text-white ',
                ]"
              >
                <div
                  class="text-left text-lg sm:text-xl font-normal tracking-[-1%]"
                >
                  {{ profileInfo.lable }}
                </div>
              </a>
            </div>

            <div class="flex flex-col gap-3">
              <h2 class="text-lg sm:text-xl font-semibold font-['Inter']">
                Giao dịch
              </h2>
              <a
                v-for="(profileTransaction, index) in profileTransaction"
                :key="index"
                :href="profileTransaction.url"
                :class="[
                  ' w-full leading-5',
                  '  focus:outline-none focus:ring-2 pb-2 border-b-2 border-gray-300',
                  isActive(profileTransaction.url)
                    ? 'text-[#5259F0]'
                    : 'text-black  hover:text-white ',
                ]"
              >
                <div
                  class="text-left text-lg sm:text-xl font-normal tracking-[-1%]"
                >
                  {{ profileTransaction.lable }}
                </div>
              </a>
            </div>

            <div class="flex flex-col gap-3">
              <h2 class="text-lg sm:text-xl font-semibold font-['Inter']">
                Lịch sử
              </h2>
              <a
                v-for="(categories, index) in categories"
                :key="index"
                :href="categories.url"
                :class="[
                  ' w-full leading-5',
                  '  focus:outline-none focus:ring-2 pb-2 border-b-2 border-gray-300',
                  isActive(categories.url)
                    ? 'text-[#5259F0]'
                    : 'text-black  hover:text-white ',
                ]"
              >
                <div
                  class="text-left text-lg sm:text-xl font-normal tracking-[-1%]"
                >
                  {{ categories.lable }}
                </div>
              </a>
            </div>
          </div>

          <div class="w-full sm:w-[60%] bg-[#F2CEDF] rounded-lg">
            <div
              :class="[
                ' my-20 p-5',
                'ring-white/60  focus:outline-none focus:ring-2',
              ]"
            >
              <slot></slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
