<template>
  <div class="my-12"></div>
  <div class="flex justify-center my-10">
    <h2
      class="px-4 py-2 col-span-full text-3xl font-extrabold font-['Inter'] text-center text-white drop-shadow-md"
      style="text-shadow: 0 0 15px #00fff0"
    >
      DANH MỤC SẢN PHẨM
    </h2>
  </div>
  <div
    class="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-4 mb-4"
  >
    <div
      v-for="category in categories"
      :key="category.id"
      class="border border-[#979797] product-transition relative overflow-hidden bg-[#F2CEDF] rounded-lg box-border"
      style="box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
    >
      <div class="flex flex-col">
        <a :href="'/sanpham/' + category.id" class="block w-full p-2 h-[70%]">
          <img
            :src="'/' + isImagePath(category.hinh_loai)"
            class="w-full rounded-lg h-28 sm:h-36"
            :alt="category.ten_loai"
          />
        </a>
        <div class="text-center px-3 py-2 h-[30%] pt-0">
          <a :href="'/sanpham/' + category.id" class="block w-full">
            <h5
              class="font-bold mt-1 mb-3 text-black text-base capitalize font-['Inter']"
              style="
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
              "
            >
              {{ category.ten_loai }}
            </h5>
            <button
              class="bg-[#3454A5CC] p-1 sm:p-2 text-white rounded w-full text-sm sn:text-base font-normal"
              style="background: rgba(52, 84, 165, 0.8)"
            >
              Xem thêm
            </button>
          </a>
        </div>
      </div>
    </div>
  </div>
  <!-- Toggle Show All button -->
  <div class="flex mt-20 justify-center">
    <button
      v-if="!showAllCategories"
      @click="toggleShowAll"
      class="p-3 text-black rounded w-full max-w-2xl text-xl font-normal"
      style="
        background: #faaebe;
        box-shadow:
          0px 4px 8px 0px rgba(0, 0, 0, 0.25),
          0px 4px 10px 0px rgba(0, 0, 0, 0.25);
      "
    >
      Xem tất cả
    </button>
  </div>
</template>

<script setup>
import { ref, onMounted, defineProps } from 'vue'
import Button from './Button.vue'
import route from 'ziggy-js'

const props = defineProps(['categoryhome'])

const categoryHome = ref(props.categoryhome)
const showAllCategories = ref(false)
const initialRowCount = 12
const categories = ref([])

const toggleShowAll = () => {
  showAllCategories.value = !showAllCategories.value
  categories.value = showAllCategories.value
    ? categoryHome.value
    : categoryHome.value.slice(0, initialRowCount)
}
const isImagePath = (path) => {
  const imagePathPrefix = 'assets/images'
  const check = path.includes(imagePathPrefix)
  if (check) {
    return path
  }
  return 'storage/' + path
}
onMounted(() => {
  categories.value = categoryHome.value.slice(0, initialRowCount)
})
</script>
