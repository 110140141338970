<script setup>
import route from 'ziggy-js'
</script>

<template>
  <div class="">
    <template v-for="cart in cart" :key="cart.id">
      <div
        class="flex justify-between items-center self-center flex-wrap sm:flex-nowrap text-center gap-5 mx-5 sm:mx-10 my-5 sm:my-8 border-b-2 border-white pb-3 sm:pb-5"
      >
        <div class="w-full sm:w-auto">
          <img
            :src="isImagePath(cart.iamges)"
            alt="Product Image"
            class="w-full aspect-video h-auto sm:h-24"
          />
        </div>

        <div class="flex flex-col gap-2">
          <div class="">
            <p
              class="text-black text-xl font-bold font-['Inter'] capitalize text-left"
            >
              {{ cart.name }}
            </p>
          </div>

          <div
            class="text-[#426B1F] text-left text-xl font-semibold font-['Inter']"
          >
            Số lượng: <span class="ms-2">{{ cart.quantity }}</span>
          </div>
          <div class="text-left">
            <span class="ms-2 font-semibold text-xl font-['Inter']">{{
              formatCurrency(cart.price)
            }}</span>
          </div>
        </div>

        <div class="w-auto sm:w-[10%] text-center ms-auto">
          <form
            :action="
              route('cart.remove', {
                product_type: 'product',
                productId: cart.id,
              })
            "
            method="POST"
          >
            <slot></slot>
            <button
              type="submit"
              class="text-lg bg-red-500 text-white w-full px-3 py-2 rounded"
            >
              xoá
            </button>
          </form>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: ['cart'],
  methods: {
    formatCurrency(value) {
      return new Intl.NumberFormat('vi-VN', {
        style: 'currency',
        currency: 'VND',
      }).format(value)
    },
    isImagePath(path) {
      const imagePathPrefix = 'assets/images'
      const check = path.includes(imagePathPrefix)
      if (check) {
        return path
      }
      const imagePathPrefixStorage = 'public/'
      const checkStorage = path.includes(imagePathPrefixStorage)
      if (checkStorage) {
        return '/storage/' + path.replace(/^public\//, '')
      }
      return '/storage/' + path
    },
  },
}
</script>
