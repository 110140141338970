import '../css/app.css'; 

import { createApp } from 'vue'
import ListCategory from '@/components/ListCategory.vue'
import Navbar from '@/components/Navbar.vue'
import InputSeach from '@/components/InputSeach.vue'
import DropDown from '@/components/DropDown.vue'
import Footer from '@/components/Footer.vue'
import FooterLink from '@/components/FooterLink.vue'
import FooterColumn from '@/components/FooterColumn.vue'
import Product from '@/Pages/Product/index.vue'
import Button from '@/components/Button.vue'
import ProductShow from '@/Pages/Product/show.vue'
import Alert from '@/components/Alert.vue'
import HistoryBill from '@/Pages/Profile/HistoryBill.vue'
import Pagination from '@/components/Pagination.vue'
import InfoUser from '@/Pages/Profile/InfoUser.vue'
import HistoryPayment from '@/Pages/Profile/HistoryPayment.vue'
import Changeinfo from '@/Pages/Profile/Changeinfo.vue'
import DescriptionBill from '@/Pages/Profile/DescriptionBill.vue'
import ToastPlugin from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-bootstrap.css'
import Cart from '@/Pages/Cart/index.vue'
import Checkout from '@/Pages/Cart/Checkout.vue'
import PaymentCard from '@/Pages/Payment/PaymentCard.vue'
import LayoutPayment from '@/Layouts/LayoutPayment.vue'
import PaymentTransfer from '@/Pages/Payment/PaymentTransfer.vue'
import Modal from '@/Layouts/Notification.vue'
import ProductFormModal from '@/Pages/Admin/Product/ProductFormModal.vue'
import Select from '@/components/Select.vue'
import IframeVideo from '@/components/IframeVideo.vue'
import LayoutProfile from '@/Layouts/LayoutProfile.vue'

const app = createApp()

app.component('list-category', ListCategory)
app.component('nav-bar', Navbar)
app.component('input-search', InputSeach)
app.component('drop-down', DropDown)
app.component('footer-component', Footer)
app.component('footer-link', FooterLink)
app.component('footer-column', FooterColumn)
app.component('button-component', Button)
app.component('product-list', Product)
app.component('product-show', ProductShow)
app.component('alert-component', Alert)
app.component('pagination-component', Pagination)
app.component('history-bill', HistoryBill)
app.component('profile-info', InfoUser)
app.component('history-payment', HistoryPayment)
app.component('profile-change', Changeinfo)
app.component('bill-description', DescriptionBill)
app.component('cart-page', Cart)
app.component('checkout-page', Checkout)
app.component('payment-card-page', PaymentCard)
app.component('layout-payment-page', LayoutPayment)
app.component('payment-transfer-page', PaymentTransfer)
app.component('notification-component', Modal)
app.component('product-form-modal', ProductFormModal)
app.component('select-component',Select)
app.component('iframe-video-component',IframeVideo)
app.component('layout-profile-page', LayoutProfile)

app.use(ToastPlugin).mount('#app_vue')
