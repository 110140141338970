<template>
  <LayoutProfile :title_page="'Lịch sử đơn hàng'">
    <div class="overflow-x-auto p-0 min-h-[100px]">
      <table class="table table-bordered min-w-full">
        <thead class="title-table">
          <tr class="tab-header gap-2 border border-black">
            <th
              scope="col"
              class="w-1/6 whitespace-nowrap text-sm font-medium text-black border-black border-e p-4"
            >
              Đơn hàng
            </th>
            <th
              scope="col"
              class="w-1/6 whitespace-nowrap text-sm font-medium text-black border-black border-e p-4"
            >
              Thời gian
            </th>
            <th
              scope="col"
              class="w-1/6 whitespace-nowrap text-sm font-medium text-black border-black border-e p-4"
            >
              Game
            </th>
            <th
              scope="col"
              class="w-1/6 whitespace-nowrap text-sm font-medium text-black border-black border-e p-4"
            >
              Tổng giá
            </th>
            <th
              scope="col"
              class="w-1/6 whitespace-nowrap text-sm font-medium text-black p-4"
            >
              Hành động
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="bill in props.bills.data"
            :key="bill.billID"
            class="border border-black"
          >
            <td
              scope="row"
              class="border-black border-e p-2 text-black text-lg font-normal text-center"
            >
              {{ bill.ma_sp }}
            </td>
            <td
              scope="row"
              class="border-black border-e p-2 text-black text-lg font-normal"
            >
              {{ bill.createdTime }}
            </td>
            <td
              scope="row"
              class="border-black border-e p-2 text-black text-lg font-normal capitalize"
            >
              {{ bill.ten_game }}
            </td>
            <td
              scope="row"
              class="border-black border-e p-2 text-black text-lg font-normal text-center"
            >
              {{ formatCurrency(bill.gia_dh) }}
            </td>
            <td class="text-[#4a4ce0] text-center">
              <a
                :href="route('profile.BillDetail', bill.billID)"
                class="underline-offset-2 text-lg font-normal"
              >
                Xem
              </a>
            </td>
          </tr>
        </tbody>
        <tfoot></tfoot>
      </table>

      <pagination-component :pagination="bills" />
    </div>
  </LayoutProfile>
</template>

<script setup lang="ts">
import route from 'ziggy-js'
import LayoutProfile from '../../Layouts/LayoutProfile.vue'
const props = defineProps(['bills'])

const formatCurrency = (value) => {
  return new Intl.NumberFormat('vi-VN', {
    style: 'currency',
    currency: 'VND',
  }).format(value)
}
</script>
