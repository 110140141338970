<script setup>
const showModal = ref(false)

const formatCurrency = (value) => {
  return new Intl.NumberFormat('vi-VN', {
    style: 'currency',
    currency: 'VND',
  }).format(value)
}

const isImagePath = (path) => {
  const imagePathPrefix = 'assets/images'
  const check = path.includes(imagePathPrefix)
  if (check) {
    return path
  }
  const imagePathPrefixStorage = 'public/'
  const checkStorage = path.includes(imagePathPrefixStorage)
  if (checkStorage) {
    return 'storage/' + path.replace(/^public\//, '')
  }

  return 'storage/' + path
}
</script>

<template>
  <section class="flex flex-col gap-6 sm:gap-12">
    <div
      class="bg-[#F2CEDF] px-4 sm:px-10 md:px-30 py-6 md:py-10 flex flex-col gap-4"
    >
      <div
        class="text text-center text-black text-lg sm:text-2xl traching-[-2%] whitespace-pre-wrap break-all font-bold font-['Inter'] capitalize mb-4 sm:mb-6"
      >
        {{ product.productTitle }}
      </div>
      <div class="grid grid-cols-1 sm:grid-cols-2 gap-0 sm:gap-8 mb-8">
        <div class="mb-6 sm:mb-0">
          <img
            id="current-slide"
            class="rounded-2 h-full object-fill aspect-video w-full"
            :src="'/' + isImagePath(currentImage)"
            alt="Image main"
            style="max-height: calc(100vh - 220px)"
            @click="showModal = true"
          />
        </div>
        <ModalComponents :show="showModal" @close="showModal = false">
          <img
            id="current-slide"
            class="rounded-2 object-fill aspect-video min-h-[200px] sm:h-full w-full"
            :src="'/' + isImagePath(currentImage)"
            alt="Image main"
            style="max-height: calc(100vh - 220px)"
          />
        </ModalComponents>

        <article class="border border-gray-400 shadow p-4 bg-white">
          <div class="container flex flex-col justify-between gap-2 h-full">
            <div class="flex">
              <div
                class="text text-left text-black text-lg sm:text-2xl traching-[-2%] whitespace-pre-wrap break-all font-bold font-['Inter'] capitalize"
              >
                {{ product.productTitle }}
              </div>
            </div>
            <div>
              <div class="d-flex justify-content-center flex-column">
                <div
                  class="font-medium font-['Inter'] text-lg sm:text-2xl text-left p-3 rounded-2 text-black"
                >
                  <span>Giá: </span> {{ formatCurrency(product.giasp) }}
                </div>
              </div>
              <div class="justify-center gap-2 mt-auto">
                <div class="flex flex-col gap-3">
                  <div class="gap-2 flex items-center justify-start">
                    <div class="flex border">
                      <button
                        @click="decrement"
                        class="text-gray-800 font-bold py-2 px-4 rounded-l"
                      >
                        -
                      </button>
                      <input
                        type="number"
                        class="text-center w-16 border-0"
                        :value="quantity"
                        min="1"
                        @input="handleQuantityChange($event)"
                      />
                      <button
                        @click="increment"
                        class="text-gray-800 font-bold py-2 px-4 rounded-r"
                      >
                        +
                      </button>
                    </div>
                  </div>

                  <div
                    class="mt-5 flex flex-wrap sm:flex-nowrap justify-start items-center w-full gap-3 sm:gap-6"
                  >
                    <form
                      class="w-full sm:w-1/2"
                      :action="route('cart.add', product.id)"
                      method="POST"
                    >
                      <slot></slot>
                      <input type="hidden" :value="quantity" name="quantity" />
                      <input type="hidden" value="payment" name="payment" />
                      <button
                        class="bg-[#E03549] text-white px-5 w-full py-3 font-bold text-xl font-['Inter'] rounded-s-lg rounded-e-lg"
                      >
                        Mua Ngay
                      </button>
                    </form>
                    <form
                      class="w-full sm:w-1/2"
                      :action="route('cart.add', product.id)"
                      method="POST"
                    >
                      <slot></slot>
                      <input type="hidden" :value="quantity" name="quantity" />
                      <button
                        class="text-white px-5 w-full py-3 font-bold text-xl font-['Inter'] bg-[#498456] rounded-s-lg rounded-e-lg"
                        type="submit"
                      >
                        Thêm giỏ hàng
                      </button>
                    </form>
                  </div>

                  <div class="mt-5 flex flex-col gap-4">
                    <div>
                      <h2
                        class="flex justify-start text-black text-lg sm:text-xl font-extrabold font-['Inter'] sm:font-bold"
                      >
                        CHI TIẾT TÀI KHOẢN
                      </h2>
                    </div>
                    <div class="details p-2">
                      <ul class="list-unstyled" id="detail_product">
                        <p
                          v-html="product.mo_ta"
                          class="text-base text-black"
                          id="description"
                        ></p>
                      </ul>
                      <div
                        class="warn"
                        style="font-size: 20px; color: rgb(53, 152, 219)"
                      ></div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>
      </div>
    </div>
    <div class="bg-[#F2CEDF]">
      <div class="border border-gray-400 col-span-2 mb-6 sm:mb-0">
        <div
          class="grid col-span-2 sm:grid-cols-3 gap-2 px-4 sm:px-10 md:px-30 py-6 md:py-10"
        >
          <img
            id="ms-1"
            :src="'/' + isImagePath(product.hinh)"
            alt="Image 0"
            @click="updateMainImage(product.hinh)"
            class="w-full aspect-video rounded border-2 p-1"
          />
          <img
            v-for="(image, index) in list_images"
            :key="index"
            :src="'/' + isImagePath(image.path)"
            :alt="'Image' + (index + 1)"
            @click="updateMainImage(image.path)"
            class="w-full aspect-video rounded border-2 p-1"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import route from 'ziggy-js'
import ModalComponents from '../../components/ModalComponents.vue'
import { ref } from 'vue'

export default {
  props: ['product', 'list_images'],
  data() {
    return {
      currentImage: this.product.hinh,
      quantity: 1,
    }
  },
  methods: {
    updateMainImage(path) {
      this.currentImage = path
    },
    increment() {
      this.quantity++
    },
    decrement() {
      if (this.quantity > 1) {
        this.quantity--
      }
    },
    handleQuantityChange(event) {
      const newQuantity = Math.max(1, parseInt(event.target.value, 10))
      this.quantity = newQuantity
    },
  },
}
</script>

<style>
@media (max-width: 640px) {
  #detail_product * {
    font-size: 18px;
    line-height: inherit;
  }
}

@media (min-width: 640px) {
  #detail_product * {
    font-size: 25px;
    line-height: inherit;
  }
}
</style>
