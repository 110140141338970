<template>
  <LayoutProfile :title_page="'Thông tin người dùng'">
    <h2
      class="text-center text-black text-lg font-medium whitespace-pre-wrap break-all capitalize"
    >
      {{ props.infor.ten_hien_thi }}
    </h2>
    <div class="px-3 py-4">
      <form :action="route('profile.update')" method="post" class="mb-3">
        <slot></slot>
        <!-- Begin Infor -->
        <div
          class="p-2 grid grid-cols-1 sm:grid-cols-3 justify-between items-center gap-4 border-black border-b-2"
        >
          <div class="">
            <div class="col-span-3 sm:col-span-1">
              <label
                for="email"
                class="block text-[#2354FF] text-base font-normal"
                >Email:</label
              >
            </div>
          </div>

          <div class="ms-auto flex gap-3 items-center col-span-2">
            <div class="col-span-3 sm:col-span-1">
              <input
                type="text"
                id="email"
                name="email"
                :value="infor.email"
                class="mt-1 p-3 text-lg font-normal border-0 w-full text-black"
                :disabled="!isEditEmail"
                :style="!isEditEmail && 'background: none;'"
              />
            </div>
            <div>
              <button
                type="button"
                @click="toggleEditEmail"
                class="text-[#2354FF] text-base font-normal"
              >
                {{ isEditEmail ? 'Hủy' : 'Chỉnh' }}
              </button>
            </div>
          </div>
        </div>
        <div
          class="p-2 grid grid-cols-1 sm:grid-cols-2 gap-4 border-black border-b-2"
        >
          <div class="col-span-3 sm:col-span-1">
            <label for="manaptien" class="block text-[#2354FF] font-semibold">
              <span class="text-base font-normal"> Số dư:</span>
            </label>
          </div>
          <div class="col-span-3 sm:col-span-1 px-3">
            <label class="block text-lg font-semibold text-black">
              {{ formatCurrency(props.infor.tien) }}</label
            >
          </div>
        </div>

        <div
          class="p-2 grid grid-cols-1 sm:grid-cols-2 gap-4 mt-2 border-black border-b-2"
        >
          <div class="col-span-3 sm:col-span-1">
            <label
              for="manaptien"
              class="block text-[#2354FF] text-base font-normal"
            >
              Mã nạp tiền:
            </label>
          </div>
          <div class="col-span-3 sm:col-span-1 px-3">
            <label class="block text-lg font-semibold text-black"
              >NAP {{ props.infor.id }}</label
            >
          </div>
        </div>

        <div
          class="p-2 grid grid-cols-1 sm:grid-cols-2 items-center gap-4 border-black border-b-2"
        >
          <div class="col-span-3 sm:col-span-1">
            <label
              for="hovaten"
              class="block text-[#2354FF] text-base font-normal"
            >
              Tên hiển thị:
            </label>
          </div>
          <div class="flex gap-3 items-center">
            <div class="col-span-3 sm:col-span-1">
              <input
                type="text"
                id="ten_hien_thi"
                name="ten_hien_thi"
                :value="infor.ten_hien_thi"
                class="mt-1 p-3 text-lg font-normal border-0 w-full text-black"
                :disabled="!isEdit"
                :style="!isEdit && 'background: none;'"
              />
            </div>
            <div>
              <button
                type="button"
                @click="toggleEdit"
                class="text-[#2354FF] text-base font-normal"
              >
                {{ isEdit ? 'Hủy' : 'Chỉnh' }}
              </button>
            </div>
          </div>
        </div>
        <div class="flex justify-center">
          <button
            :type="isEdit && isEditEmail ? 'submit' : 'button'"
            :class="[
              'w-60 text-white p-3 rounded mt-2 bg-blue-500',
              isEdit ? 'opacity-100' : 'opacity-60',
            ]"
          >
            Cập nhật
          </button>
        </div>
      </form>
    </div>
  </LayoutProfile>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import LayoutProfile from '../../Layouts/LayoutProfile.vue'
import route from 'ziggy-js'

const props = defineProps(['infor'])

const isEditEmail = ref(false)
const isEdit = ref(false)

const formatCurrency = (value) => {
  return new Intl.NumberFormat('vi-VN', {
    style: 'currency',
    currency: 'VND',
  }).format(value)
}

const toggleEdit = () => {
  isEdit.value = !isEdit.value
}

const toggleEditEmail = () => {
  isEditEmail.value = !isEditEmail.value
}
</script>
