<template>
  <div class="p-0 sm:p-4 pt-0">
    <div class="box-paiement p-3">
      <form
        name="napcard"
        method="post"
        enctype="multipart/form-data"
        :action="route('recharge.card.post')"
        class=""
      >
        <input name="_token" type="hidden" :value="csrf" />
        <div class="gap-2 mb-5 pb-0 w-full">
          <div class="flex flex-wrap gap-4 mb-5">
            <select
              class="bg-white text-black font-normal rounded text-base tracking-[-1%] w-full"
              name="type"
              @change="ShowVAT"
              required
            >
              <option
                value=""
                class="text-xl font-normal tracking-[-1%] font-['DM Mono']"
              >
                -- Chọn loại thẻ --
              </option>
              <option
                v-for="loaithe in loaithes"
                :key="loaithe.tenloaithe"
                :value="loaithe.tenloaithe"
              >
                {{ loaithe.tenloaithe }}
              </option>
            </select>
          </div>
          <div class="flex flex-wrap gap-4 w-full">
            <select
              class="bg-white text-black rounded w-full text-base font-normal tracking-[-1%]"
              name="menhgia"
              required
            >
              <option value=" ">-- Chọn mệnh giá - chiết khấu--</option>
              <option
                v-for="price in prices"
                :key="price.menhgia"
                :value="price.menhgia"
              >
                {{ formatCurrency(price.menhgia) }} - {{ VATCard }}
              </option>
            </select>
          </div>
        </div>
        <div class="flex flex-col gap-3 w-full">
          <div>
            <div class="flex flex-wrap gap-6 w-full">
              <input
                type="text"
                class="bg-white text-black w-full text-base rounded font-normal tracking-[-1%]"
                v-model="seri"
                name="seri"
                placeholder="Số serial của thẻ"
                required
              />
            </div>
          </div>
          <div>
            <div class="flex flex-wrap gap-6 w-full">
              <input
                type="text"
                class="bg-white text-black w-full text-base rounded font-normal tracking-[-1%]"
                v-model="mathe"
                name="mathe"
                placeholder="Mã thẻ cào "
                required
              />
            </div>
          </div>
        </div>
        <div class="mt-5 flex justify-center">
          <button
            type="submit"
            class="bg-[#353F75] text-white text-base rounded font-semibold p-3 tracking-[-1%] min-h-10 border border-black"
          >
            Nạp Thẻ Cào
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import route from 'ziggy-js'
const props = defineProps(['csrf'])
const loaithes = [
  {
    id: 1,
    tenloaithe: 'Viettel',
    VAT: '20%',
  },

  {
    id: 3,
    tenloaithe: 'Vinaphone',
    VAT: '20%',
  },
  {
    id: 4,
    tenloaithe: 'Zing',
    VAT: '30%',
  },
  {
    id: 5,
    tenloaithe: 'Vietnamobile',
    VAT: '20%',
  },
  {
    id: 6,
    tenloaithe: 'Mobifone',
    VAT: '30%',
  },
]
const prices = [
  {
    id: 1,
    menhgia: 10000,
  },
  {
    id: 2,
    menhgia: 20000,
  },
  {
    id: 3,
    menhgia: 50000,
  },
  {
    id: 4,
    menhgia: 100000,
  },
  {
    id: 5,
    menhgia: 200000,
  },
  {
    id: 6,
    menhgia: 300000,
  },
  {
    id: 7,
    menhgia: 500000,
  },
  {
    id: 8,
    menhgia: 1000000,
  },
]
let mathe = ''

const formatCurrency = (value) => {
  return new Intl.NumberFormat('vi-VN', {
    style: 'currency',
    currency: 'VND',
  }).format(value)
}

const VATCard = ref('0%')

const ShowVAT = (event) => {
  VATCard.value =
    loaithes.find((loaithe) => loaithe.tenloaithe === event.target.value)
      ?.VAT || '0%'
}
</script>
